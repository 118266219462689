import React, { useState } from 'react';
import ToggleGrid from './components/ToggleGrid';
import ProbabilitiesTable from './components/ProbabilitiesTable';
import { calculateProbabilities } from './utils';
import "./App.css";

const App = () => {
  const [activeCells, setActiveCells] = useState(Array(16).fill(0)); // Initialize with 0 (default image)
  const maxActiveCells = 9;

  const handleToggle = (index) => {
    const activeCount = activeCells.filter(cell => cell !== 0).length;
    if (activeCells[index] !== 0 || activeCount < maxActiveCells) {
      const newActiveCells = [...activeCells];
      newActiveCells[index] = newActiveCells[index] === 0 ? index + 1 : 0; // Toggle between default and active image
      setActiveCells(newActiveCells);
    }
  };

  const probabilities = calculateProbabilities(activeCells);

  return (
    
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <div style={{ position: 'relative', zIndex: 1, display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '600px', width: '100%' }}>
          <ToggleGrid activeCells={activeCells} handleToggle={handleToggle} />
          <ProbabilitiesTable probabilities={probabilities} />
        </div>
      </div>
    </div>
  );
};

export default App;