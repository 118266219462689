import React from 'react';
import { Grid } from '@mui/material';
import GridCell from './GridCell';

const ToggleGrid = ({ activeCells, handleToggle }) => {
  return (
    <Grid container spacing={1}>
      {activeCells.map((imageIndex, index) => (
        <Grid item xs={3} key={index}>
          <GridCell
            imageIndex={imageIndex}
            onClick={() => handleToggle(index)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ToggleGrid;