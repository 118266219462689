// GridCell.js
import React from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)(({ theme, ownerState }) => ({
  '& img': {
    width: '100%',
    height: 'auto',
  },
}));

const GridCell = ({ imageIndex, onClick }) => {
  const images = Array.from({ length: 17 }, (_, i) => require(`../assets/images/img${i}.png`));

  return (
    <StyledIconButton
      ownerState={{ active: imageIndex !== 0 }}
      onClick={onClick}
      fullWidth
    >
      <img src={images[imageIndex]} alt={`img${imageIndex}`} />
    </StyledIconButton>
  );
};

export default GridCell;