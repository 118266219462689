// utils.js
export const countCompletedLines = (activeCells) => {
    const size = Math.sqrt(activeCells.length);
    let completedRows = 0;
    let completedCols = 0;
    let completedDiagonals = 0;
  
    // Check rows
    for (let i = 0; i < size; i++) {
      if (activeCells.slice(i * size, i * size + size).every(cell => cell)) {
        completedRows++;
      }
    }
  
    // Check columns
    for (let i = 0; i < size; i++) {
      if (activeCells.filter((_, index) => index % size === i).every(cell => cell)) {
        completedCols++;
      }
    }
  
    // Check diagonals
    if (activeCells.filter((_, index) => index % (size + 1) === 0).every(cell => cell)) {
      completedDiagonals++;
    }
    if (activeCells.filter((_, index) => index % (size - 1) === 0 && index !== 0 && index !== activeCells.length - 1).every(cell => cell)) {
      completedDiagonals++;
    }
  
    return { completedRows, completedCols, completedDiagonals };
  };
  
  export const getCombinations = (arr, k) => {
    const combinations = [];
    const generate = (start, combo) => {
      if (combo.length === k) {
        combinations.push(combo);
        return;
      }
      for (let i = start; i < arr.length; i++) {
        generate(i + 1, combo.concat(arr[i]));
      }
    };
    generate(0, []);
    return combinations;
  };
  
  export const calculateProbabilities = (activeCells) => {
    const activeCount = activeCells.filter(Boolean).length;
    const cellsNeeded = 9 - activeCount;
    const emptyCells = activeCells.map((cell, index) => !cell ? index : null).filter(index => index !== null);
  
    const combinations = getCombinations(emptyCells, cellsNeeded);
    const lineCounts = { 0: 0, 1: 0, 2: 0, 3: 0 };
  
    combinations.forEach(combination => {
      const testCells = [...activeCells];
      combination.forEach(index => testCells[index] = true);
      const { completedRows, completedCols, completedDiagonals } = countCompletedLines(testCells);
      const totalLines = completedRows + completedCols + completedDiagonals;
      if (totalLines <= 3) {
        lineCounts[totalLines]++;
      }
    });
  
    const totalCombinations = combinations.length;
    return {
      0: lineCounts[0] / totalCombinations,
      1: lineCounts[1] / totalCombinations,
      2: lineCounts[2] / totalCombinations,
      3: lineCounts[3] / totalCombinations,
    };
  };