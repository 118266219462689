import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { calculateProbabilities } from '../utils';

const styles = {
  tableHeader: {
    backgroundColor: '#f5f5f5',
  },
  tableCell: {
    fontWeight: 'bold',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
};

const reshuffleProbabilities = calculateProbabilities(Array(16).fill(0));

const ProbabilitiesTable = ({ probabilities, classes }) => {
  const keepExpectation = probabilities[0]*0 + probabilities[1]*1 + probabilities[2]*2 + probabilities[3]*3;
  const reshuffleExpectation = reshuffleProbabilities[0]*0 + reshuffleProbabilities[1]*1 + reshuffleProbabilities[2]*2 + reshuffleProbabilities[3]*3;

  const advice = keepExpectation > reshuffleExpectation ? 'Keep' : keepExpectation < reshuffleExpectation ? 'Reshuffle' : '-';

  return (
    <TableContainer component={Paper} elevation={3}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell className={classes.tableCell}>Probabilities</TableCell>
            <TableCell className={classes.tableCell}>0 Lines</TableCell>
            <TableCell className={classes.tableCell}>1 Line</TableCell>
            <TableCell className={classes.tableCell}>2 Lines</TableCell>
            <TableCell className={classes.tableCell}>3 Lines</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell>Keep</TableCell>
            <TableCell>{(probabilities[0] * 100).toFixed(3)}%</TableCell>
            <TableCell>{(probabilities[1] * 100).toFixed(3)}%</TableCell>
            <TableCell>{(probabilities[2] * 100).toFixed(3)}%</TableCell>
            <TableCell>{(probabilities[3] * 100).toFixed(3)}%</TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>Reshuffle</TableCell>
            <TableCell>{(reshuffleProbabilities[0] * 100).toFixed(3)}%</TableCell>
            <TableCell>{(reshuffleProbabilities[1] * 100).toFixed(3)}%</TableCell>
            <TableCell>{(reshuffleProbabilities[2] * 100).toFixed(3)}%</TableCell>
            <TableCell>{(reshuffleProbabilities[3] * 100).toFixed(3)}%</TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell colSpan={5} align="center">
              <Typography variant="h6">Advice: {advice}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withStyles(styles)(ProbabilitiesTable);